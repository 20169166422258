import { UsePDFInstance } from '@react-pdf/renderer';

interface ResumePDFViewerProps
  extends React.IframeHTMLAttributes<HTMLIFrameElement> {
  innerRef?: React.RefObject<HTMLIFrameElement>;
  showToolbar?: boolean;
  instance: UsePDFInstance;
}

export const ResumePDFViewer: React.FC<ResumePDFViewerProps> = ({
  innerRef,
  instance,
  showToolbar = true,
  ...props
}: ResumePDFViewerProps) => {
  const src = instance.url
    ? `${instance.url}#toolbar=${showToolbar ? 1 : 0}`
    : undefined;

  return <iframe src={src} ref={innerRef} {...props} />;
};

export default ResumePDFViewer;
