import { Font } from '@react-pdf/renderer';

import './registerLato.ts';
import './registerMerriweather.ts';
import './registerMontserrat.ts';
import './registerNunito.ts';
import './registerOpenSans.ts';
import './registerOswald.ts';
import './registerPTSans.ts';
import './registerPlayfairDisplay.ts';
import './registerRaleway.ts';
import './registerRoboto.ts';

Font.registerHyphenationCallback((word) => [word]);
