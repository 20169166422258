import { Box, Grid, Image, Modal, Text, useMantineTheme } from '@mantine/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TemplateEntry } from './ResumeDocumentView';

interface TemplateModalProps {
  opened: boolean;
  onClose: () => void;
  templates: TemplateEntry[];
  onTemplateSelect: (template: TemplateEntry) => void;
  isMobile: boolean;
}

export const TemplateModal: React.FC<TemplateModalProps> = ({
  opened,
  onClose,
  templates,
  onTemplateSelect,
  isMobile,
}) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={
        <Text fz="lg" fw="bolder">
          {t('view.pdf.templateModalTitle')}
        </Text>
      }
      size={isMobile ? 'full' : 'lg'}
      fullScreen={isMobile}
    >
      <Grid>
        {templates.map((template) => (
          <Grid.Col key={template.label} span={6}>
            <Box
              style={{
                border: `1px solid ${theme.colors.gray[4]}`,
                borderRadius: theme.radius.md,
                padding: theme.spacing.md,
                textAlign: 'center',
                cursor: 'pointer',
              }}
              onClick={() => onTemplateSelect(template)}
            >
              <picture>
                {template.preview && (
                  <source srcSet={template.preview} type="image/webp" />
                )}
                <Image
                  src={template.previewFallback}
                  alt={`${template.label} Preview`}
                  height={150}
                  fit="contain"
                />
              </picture>
              <Text mt="sm" fw="bold">
                {template.label}
              </Text>
            </Box>
          </Grid.Col>
        ))}
      </Grid>
    </Modal>
  );
};
